<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <!--  -->
    <div class="card">
      <div class="card-body">
        <div class="row">
          <div class="col">
            <DxDataGrid
              id="gvUsers"
              ref="grid"
              v-bind="options"
              :data-source="usersStore"
              @data-error-occurred="dataOccurred"
              @init-new-row="onNewRow"
              @editing-start="onEditRow"
            >
              <DxColumn data-field="userName" caption="Имя пользователя">
                <DxRequiredRule />
              </DxColumn>
              <DxColumn data-field="name" caption="ФИО">
                <DxRequiredRule />
              </DxColumn>
              <DxColumn :width="125" data-field="roleId" caption="Роль">
                <DxLookup :data-source="rolesStore" display-expr="name" value-expr="id" />
              </DxColumn>
              <DxColumn data-field="password" caption="Пароль" :visible="false" />
              <DxColumn data-field="confirmPassword" caption="Подтверждение пароля" :visible="false" />

              <DxEditing :allow-updating="true" :allow-adding="true" :allow-deleting="true" mode="popup">
                <DxPopup :show-title="true" width="800" height="auto" />
                <DxForm>
                  <DxItem :col-count="2" :col-span="2" item-type="group">
                    <DxItem data-field="userName" />
                    <DxItem data-field="name" />
                  </DxItem>

                  <DxItem :col-count="2" :col-span="2" item-type="group">
                    <DxItem data-field="password" :editor-options="{ mode: 'password', inputAttr: { autocomplete: 'new-password' } }" />
                    <DxItem
                      data-field="confirmPassword"
                      :editor-options="{ mode: 'password', inputAttr: { autocomplete: 'new-password' } }"
                    />
                  </DxItem>
                  <DxItem :col-count="2" :col-span="2" item-type="group">
                    <DxItem data-field="roleId" />
                  </DxItem>
                </DxForm>
              </DxEditing>

              <DxFilterRow :visible="true" />
              <DxPaging :page-size="10" />
              <DxPager
                :visible="true"
                :allowed-page-sizes="[5, 10, 50]"
                :show-page-size-selector="true"
                :show-navigation-buttons="true"
                :show-info="true"
              />
            </DxDataGrid>
          </div>
        </div>
      </div>
    </div>
    <!--  -->
  </Layout>
</template>

<script>
import Layout from "../../layouts/main.vue";
import PageHeader from "@/components/page-header";

import createStoreExtend from "@/helpers/grid/store";
import settings from "@/helpers/grid/settings.js";
import { DxPopup, DxForm } from "devextreme-vue/data-grid";
import { DxItem } from "devextreme-vue/form";

export default {
  components: {
    Layout,
    PageHeader,
    DxPopup,
    DxForm,
    DxItem,
  },
  data() {
    return {
      isEditing: false,
      title: "Пользователи",
      items: [
        {
          text: "Администрирование",
          href: "/",
        },
        {
          text: "Пользователи",
          active: true,
        },
      ],

      options: settings,
      rolesStore: createStoreExtend({
        key: "id",
        loadUrl: `${process.env.VUE_APP_URL}${process.env.VUE_APP_ROLES_GET}`,
      }),
      usersStore: createStoreExtend({
        key: "id",
        loadUrl: `${process.env.VUE_APP_URL}/api/devextreme/users/users`,
        insertUrl: `${process.env.VUE_APP_URL}/api/devextreme/users/create`,
        updateUrl: `${process.env.VUE_APP_URL}/api/devextreme/users/update`,
        deleteUrl: `${process.env.VUE_APP_URL}/api/devextreme/users/delete`,
      }),
    };
  },
  methods: {
    dataOccurred(e) {
      console.log(e);
    },
    onNewRow(e) {
      e.component.option("editing.popup.title", "Создание пользователя");
    },
    onEditRow(e) {
      e.component.option("editing.popup.title", "Редактирование пользователя");
    },
  },
};
</script>
